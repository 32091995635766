define(['app', 'siteObj', 'cookie', 'ePopup', 'queryString'], function(app, siteObj, cookie, ePopup, queryString) {

  var InternationalOverlay = function() {
    var self = this;
    var epopupSubscription;
    var localisationPopup;

    var _config = {
      chosenSubsite: cookie.get(siteObj.subsiteCode + '_chosenSubsite_V6'),
      referringSite: siteObj.siteCode + '_' + siteObj.siteDefaultLocale,
      subsiteCode: siteObj.subsiteCode,
      legacyChosenCountry: cookie.get('chosenCountry'),
      countrySelected: window.location.search.indexOf('countrySelected=Y') > -1,
      contentUrl: siteObj.siteURL + siteObj.siteDefaultLocale + '/' + siteObj.currencyType + '/' + siteObj.customerLocation + '/switchLocale.overlay',
      getUrlForSave: function(subsiteCode) {
        return siteObj.siteURL + siteObj.siteDefaultLocale + '/' + siteObj.currencyType + '/sessionSettingsSave.session?internationaloverlay=true&subsitecode=' + subsiteCode;
      }
    };

    var _init = function() {

      if (self.config.chosenSubsite !== self.config.subsiteCode) {
        if (self.config.legacyChosenCountry === 'AD') {
          cookie.remove('chosenCountry', {
            path: '/'
          });
          self.saveSettings(self.config.subsiteCode, false);
        } else if (self.config.countrySelected) {
          self.saveSettings(self.config.subsiteCode, false); //The chosenSubsite cookie should already be set (only here as defensive check)
        } else {
          var parsed = queryString.parse(window.location.search);
          var overrideCustomerLocation = parsed.overrideCustomerLocation;
          if (overrideCustomerLocation) {
            self.config.contentUrl += '?overrideCustomerLocation=' + overrideCustomerLocation;
          }
          app.ajax.get({
            url: self.config.contentUrl,
            success: function(popupContent) {
              self.showLocalePopup(popupContent);
            },
            error: function() {
              console.log('Failed to load locale popup');
            }
          });
        }
      }
      return self;
    };

    var _showLocalePopup = function(content, popup) {
      if (content.length > 0) {
        if (!localisationPopup) {
          localisationPopup = popup || new ePopup(content, 'internationalOverlay_popup', true);
        }
        app.publish('tracking/record', 'locale popup', 'Show', 'Location: ' + siteObj.customerLocation);
        var stayButton = document.querySelectorAll('.internationalOverlay_stayButton');
        if (stayButton.length > 0) {
          app.element.on('click', self.stayHere(localisationPopup), stayButton[0]);
        }
        epopupSubscription = app.subscribe('epopup/default-close', self.stayHere(localisationPopup));
        var goButton = document.querySelectorAll('.internationalOverlay_goButton');
        if (goButton.length > 0) {
          app.element.on('click', self.goThere(localisationPopup), goButton[0]);
        }
      }
    };

    var _stayHere = function(thePopup) {
      return function() {
        epopupSubscription.remove();
        app.publish('tracking/record', 'locale popup', 'Stay On Site', 'Location: ' + self.config.subsiteCode);
        self.saveSettings(self.config.subsiteCode, false);
        thePopup.close();
      };
    };

    var _goThere = function(thePopup) {
      return function() {
        var overlayElement = document.querySelector('.internationalOverlay');
        var suggestedSubsite = overlayElement.getAttribute('data-suggested-subsite');
        app.publish('tracking/record', 'locale popup', 'Goto Site', 'Location: ' + suggestedSubsite);
        self.saveSettings(suggestedSubsite, true);
        thePopup.close();
      };
    };

    /**
     * Save the session settings
     */
    var _saveSettings = function(country, reload) {
      app.ajax.get({
        url: self.config.getUrlForSave(country),
        success: function() {
          self.settingsSaved(reload);
        }
      });
    };

    var _settingsSaved = function(reload) {
      app.publish('sessionSettings/save');
      if (reload) {
        self.reloadPage();
      }
    };

    var _reloadPage = function() {
      var parsed = queryString.parse(window.location.search);
      parsed.feature = 'locale-popup';
      parsed.referringSite = self.config.referringSite;
      window.location.search = queryString.stringify(parsed);
    };

    self.config = _config;
    self.init = _init;
    self.showLocalePopup = _showLocalePopup;
    self.stayHere = _stayHere;
    self.goThere = _goThere;
    self.saveSettings = _saveSettings;
    self.settingsSaved = _settingsSaved;
    self.reloadPage = _reloadPage;
  };

  return new InternationalOverlay().init();

});
